@import "src/styles/variables";

.favorite{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: $light-gray;
  border-radius: 15px;
}

.onload{
  height: 57px;
  h2{
    height: 18px;
    width:30%;
    background: $mid-gray;
    border-radius: 5px;
  }
}