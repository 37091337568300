@import "src/styles/variables";

.statics{
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: $light-gray;
}

.staticsBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  img{
    width: 50px;
    height: 50px;
    border-radius: 30px;
    object-fit: cover;
  }
  span{
    opacity: 0.5;
    font-size: 0.7rem;
  }
}

.staticsDetail{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  padding:1rem;
  border-radius: 10px;
  width:29.5%;
  p{
    text-align:center;
  }
}

.service{
  flex-basis: calc(50% - 0.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $light-gray;
  border-radius: 15px;
  padding: 1.5rem;
  p{
    margin-top: 1rem;
    text-align: center;
  }
}