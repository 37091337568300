//primary color set
$primary-color: var(--cms-primary);
$primary-inner-text: var(--cms-primary-inner-text);
$primary-hover: var(--cms-primary-hover);
$primary-inner-text-hover: var(--cms-primary-inner-text-hover);

//secondary color set
$secondary-color: var(--cms-secondary);
$secondary-inner-text: var(--cms-secondary-inner-text);
$secondary-hover: var(--cms-secondary-hover);
$secondary-inner-text-hover: var(--cms-secondary-inner-text-hover);

//third color set
$third-color: var(--cms-third);
$third-inner-text: var(--cms-third-inner-text);
$third-hover: var(--cms-third-hover);
$third-inner-text-hover: var(--cms-third-inner-text-hover);

// use cases
$main-background: var(--cms-background);
$text-color: var(--cms-text-color);
$disable-color: var(--cms-disable-color);
$disable-inner-color: var(--cms-black);
$confirm-btn: var(--cms-confirm-btn);
$confirm-btn-inner: var(--cms-confirm-btn-inner);
$confirm-btn-hover: var(--cms-confirm-btn-hover);
$confirm-btn-inner-hover: var(--cms-confirm-btn-inner-hover);
$delete-btn: var(--cms-delete-btn);
$delete-btn-inner: var(--cms-delete-btn-inner);
$delete-btn-hover: var(--cms-delete-btn-hover);
$delete-btn-inner-hover: var(--cms-delete-btn-inner-hover);

//overall colors
$white: var(--cms-white);
$black: var(--cms-black);
$light-gray: var(--cms-light-gray);
$green: var(--cms-green);
$blue: var(--cms-blue);
$red: var(--cms-red);
$mid-gray: var(--cms-mid-gray);
$dark-gray: var(--cms-dark-gray);
$orange: var(--cms-orange);
$light-orange: var(--cms-light-orange);
$yellow: var(--cms-yellow);
$transparent: var(--cms-transparent);

//form colors
$form-border:var(--cms-form-border);
$form-background:var(--cms-form-background);

//shadows
$box-shadow: 0px 6px 12px 0px var( --cms-shadow-a);
$box-shadow-small: 0 1px 2px var( --cms-shadow-b), inset 0 -15px 10px -12px var( --cms-shadow-b);
$box-shadow-large: 0 10px 20px var( --cms-shadow-b);
$box-shadow-button: 0px 4px 4px rgba(0, 0, 0, 0.25);

//Paddings
$main-container-padding:0.75rem;
$data-container-padding:0.75rem;
$slick-slide-padding:0.75rem;