@import 'src/styles/variables';

.circle{
  width: 14px;
  height: 14px;
  border-radius: 8px;
  background: $mid-gray;
}

.text{
  height: 14px;
  border-radius: 8px;
}
