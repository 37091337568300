@import "src/styles/variables";

.container{
  width: 100%;
}

.blocks{
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 5%;
  li{
    display: flex;
    color: $mid-gray;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    border: 1px solid $form-border;
    background: $main-background;
    margin-bottom: 5%;
    border-radius: 5px;
    cursor: pointer;
  }
  li::before {
    content:'';
    float:left;
    padding-top:100%;
  }
}

.active{
  background: $primary-color!important;
  border: 1px solid $primary-color!important;
  color:$white!important;
}