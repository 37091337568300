@import "src/styles/variables";

.parkingLocationMarker{
    display: flex;
    position: absolute;
    width: max-content;
    align-items: center;
    height: 35px;
    border-radius: 50px;
    padding: 0.5rem;
    gap: 0.25rem;
    transform: translate(-20px, calc(-100% - 6px));
    p{
        color: $white;
        font-size: 1rem;
    }
    .triangle-down {
        position: absolute;
        bottom: 0;
        left: 15px;
        transform: translateY(8px);
        polygon{
            fill: $green;
        }
    }
}

.marker-green{
    background: $green;
}