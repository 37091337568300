@import "src/styles/variables";


.contents{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.actions{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding: 15px 0;
    background: #EEFFF5;
    z-index: 1000;
    gap: 15px;
}