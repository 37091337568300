@import 'src/styles/variables';

.container{
  position: relative;
  height: 120px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  ul{
    position: absolute;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    li{
      display: flex;
      height: 40px;
      align-items: center;
      p{
        font-size: 0.8rem;
        opacity: 0.4;
        -webkit-transition: .2s;
        transition: .2s;
      }
    }
  }
}

.active{
  p{
    color: $primary-color;
    opacity: 1!important;
    font-size: 1.2rem!important;
    font-weight: bold;
  }
}