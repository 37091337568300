@import "src/styles/variables";
@import "./colorThemes";

.customBtn{
  height: 38px;
  border-radius: 40px;
  padding: 0 1.5rem;
  width: max-content;
  color: $white;
  background: none;
}

.customBtn:first-letter{
  text-transform:uppercase;
}

@function property($theme, $property) {
  @return map-get($theme, $property)
}
@each $theme in $themes {
  .customBtn-#{property($theme, theme)} {
    background: property($theme, background) !important;
    color: property($theme, color);
    border:1px solid property($theme, border);
    svg path{
      @if property($theme, color) != initial {
        fill: property($theme, color)!important;
      }
    }
  }
  .customBtn-#{property($theme, theme)}:hover{
    background: property($theme, hBackground) !important;
    color: property($theme, hColor) !important;
    border:1px solid property($theme, hBorder)!important;
    svg path{
      @if property($theme, hColor) != initial {
        fill: property($theme, hColor)!important;
      }
    }
  }
}
