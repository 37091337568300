@import "src/styles/variables";

.rows {
    display: flex;
    margin-bottom: 10px;

    p {
        width: 50%;
        text-transform: capitalize;
    }
}

.rows:first-child {
    margin-bottom: 20px;

    p {
        color: $mid-gray;
    }

}