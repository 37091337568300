@import "src/styles/variables";

.avatar{
  img{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
  }
}

.contents{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.form{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

.actions{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  padding: 15px 0;
  z-index: 1000;
  gap: 15px;
}