@import 'src/styles/variables';

.date, .time{
  position: relative;
  width: 100%;
  display: flex;
  background: $light-gray;
  padding: 1rem;
  border-radius: 15px;
}

.timeDivider{
  display: flex;
  flex-direction: column;
  li{
    font-size: 0.9rem;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $mid-gray;
  }
  :nth-child(2){
    color: $primary-color;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.hr{
  position: absolute;
  width: calc(100% - 2rem);
  border-color: $form-border;
  margin: 0;
}