@import "src/styles/variables";

.contents{
  width:100%;
  background: $light-gray;
  padding: 1rem;
  border-radius: 30px;
}

.navBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: #B9B9B950;
}

.next{
  transform: rotate(180deg);
}