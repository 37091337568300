@import "src/styles/variables";

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  li{
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
}