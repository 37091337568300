@import "src/styles/variables";

.addBtn{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-button;
  cursor: pointer;
  background: $primary-color;
  width: 48px;
  height: 48px;
  border-radius: 36px;
  bottom: 32px;
  right: 1.5rem;
}

.vehicleRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: $light-gray;
  border-radius: 15px;
  p{
    margin-top: 10px;
    opacity:0.6;
  }
}

.onload{
  height: 75px;
  h2{
    height: 18px;
    width:30%;
    background: $mid-gray;
    border-radius: 5px;
  }
  p{
    height: 15px;
    width:25%;
    background: $white;
    border-radius: 5px;
  }
}

.pointerArrow{
  display: flex;
  position: absolute;
  align-items: end;
  bottom:0;
  right: 0;
  width:200px;
  height:24px;
  img{
    width: 64px;
  }
  p{
    margin-bottom: -5px;
  }
}