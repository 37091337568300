@import "../../../../../styles/variables";

.button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.control{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: $white;
  box-shadow: $box-shadow-button;
}