@import "src/styles/variables";

.wrapper{
    display: flex;
    background: $light-gray;
    padding: 20px;
    border-radius: 15px;
    gap:20px;
    button{
        background: $white;
        border:1px solid $mid-gray;
        padding: 10px 30px;
        border-radius: 5px;
        text-transform: capitalize;
    }
}

.photo{
    width: 140px;
    height: 140px;
    border-radius: 5px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.caption{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}