@import "src/styles/variables";

.addBtn{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-button;
  cursor: pointer;
  background: $primary-color;
  width: 48px;
  height: 48px;
  border-radius: 36px;
  bottom: 32px;
  right: 1.5rem;
}

.paymentRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: $light-gray;
  border-radius: 15px;
  p{
    margin-top: 10px;
    opacity:0.6;
    text-transform: capitalize;
  }
  h2{
    font-weight: 400!important;
    text-transform: capitalize;
  }
}

.avatar{
  width:30px;
  height:30px;
  object-fit: cover;
}

.onload{
  height: 75px;
  h2{
    height: 18px;
    width:30%;
    background: $mid-gray;
    border-radius: 5px;
  }
  p{
    height: 15px;
    width:25%;
    background: $white;
    border-radius: 5px;
  }
  span{
    height: 30px;
    width:30px;
    background: $mid-gray;
    border-radius: 21px;
  }
}