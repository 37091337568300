@import "src/styles/variables";

.card{
    background: $light-gray;
    padding: 1rem;
    border-radius: 15px;
    margin-bottom: 1.5rem;
}

.card:last-child{
    margin-bottom: 0!important;
}

.row{
    display: flex;
    margin-bottom: 15px;
    gap:10px;
    span{
        text-transform: capitalize;
        color: $dark-gray;
    }
}