@import 'src/styles/variables';

.wrapper{
  display: flex;
  height: 250px;
  flex-direction: column;
  overflow-y: auto;
  div{
    padding: 1rem 0.5rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid $light-gray;
    cursor:pointer;
  }
  p{
    width:50%;
    white-space: nowrap;
    overflow: hidden;
  }
  span{
    opacity: 0.75;
  }
}

.row:hover{
  background: $primary-color;
  p, span {color: $white}
}