@import "src/styles/variables";

.titles{
  display: flex;
  border-bottom: 1px solid $form-border;
  padding: 0 1.5rem;
  width: 100%;
  justify-content: space-between;
  li{
    display: flex;
    justify-content: center;
    padding:1rem 0;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: $disable-color;
    text-transform: capitalize;
  }
}
.active {
  border-color: $primary-color!important;
  color: $primary-color!important;
  font-weight: bold;
}