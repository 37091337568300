@import "src/styles/variables";

.qrWrapper{
    width: 100%;
    background:$mid-gray;
}

.qrWrapper::after {
    content:'';
    float:left;
    padding-top:100%;
}