@import 'src/styles/variables';

.container{
  position: relative;
  display: flex;
  flex-direction: column;
  input{
    height: 36px;
    padding: 0.5rem 0.75rem;
    border: 1px solid $form-border;
    border-radius: 18px;
  }
  label{
    margin-bottom: 0.25rem;
  }
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 26px;
    height: 26px;
    padding: 4px;
    bottom:5px;
    right: 10px;
    cursor: pointer;
  }
  .hint{
    font-size: 0.7rem;
    opacity: 0.8;
    margin-top: 0.5rem;
  }
}

.prefix{
  position: absolute;
  font-size: 0.8rem;
  bottom:10px;
  left:0.75rem;
  opacity: 0.50;
}