.light-theme {
  // primary
  --cms-primary: #00C853;
  --cms-primary-inner-text: white;
  --cms-primary-hover: #00a240;
  --cms-primary-inner-text-hover: white;

  // secondary
  --cms-secondary: #BAE7FF;
  --cms-secondary-inner-text: white;
  --cms-secondary-hover: #728794;
  --cms-secondary-inner-text-hover: white;

  // third
  --cms-third: #c4c4c4;
  --cms-third-inner-text: white;
  --cms-third-hover: #7c7c7c;
  --cms-third-inner-text-hover: white;

  // use cases
  --cms-background:#F9F9F9;
  --cms-text-color: #363636;
  --cms-disable-color: #a09c9c;
  --cms-confirm-btn: #40b400;
  --cms-confirm-btn-inner: #fff;
  --cms-confirm-btn-hover: #68b934;
  --cms-confirm-btn-inner-hover: #fff;
  --cms-delete-btn: #ff0082;
  --cms-delete-btn-inner: #ffffff;
  --cms-delete-btn-hover: #ff61ae;
  --cms-delete-btn-inner-hover: #ffffff;

  //overall colors
  --cms-white:#ffffff;
  --cms-black:#000000;
  --cms-light-gray:#f5f5f5;
  --cms-green: #40b400;
  --cms-blue: #1890FF;
  --cms-red: #ff0000;
  --cms-mid-gray:#B9B9B9;
  --cms-dark-gray:#6B7280;
  --cms-yellow: #EBC337;
  --cms-orange: #fa8c16;
  --cms-light-orange: #FFE7BA;
  --cms-transparent: transparent;

  //form colors
  --cms-form-border:#D9D9D9;
  --cms-form-background: rgba(255, 255, 255, 0);

  // shadows
  --cms-shadow-a: rgba(52, 37, 31, 0.04);
  --cms-shadow-b: rgba(0,0,0,0.2);
}