@import "src/styles/variables";

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid $form-border;
  padding: 1rem;
  border-radius: 20px;
}

.hours{
  border: 1px solid $orange;
  background: $light-orange;
  padding: 5px 9px;
  border-radius: 10px;
}