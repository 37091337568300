@import "src/styles/variables";

.container{
  overflow-y: hidden;
  -webkit-transition: 1s;
  transition: 1s;
}

.handle{
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 30px;
  background: $white;
  box-shadow: $box-shadow-button;
  bottom:-40px;
  left: calc(50% - 29px);
  div{
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.openHandle{
  div{
    transform: rotate(180deg);
  }
}