@import "src/styles/variables";

.container{
  position: relative;
  width:100%;
}

.result{
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 300px;
  background: $white;
  height: 200px;
  border: 1px solid gray;
  z-index: 100;
  overflow-y: auto;
  ul{
    li{
      padding: 0.25rem;
      cursor: pointer;
    }
  }
}