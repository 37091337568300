@import "src/styles/variables";

.wrapper{
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.items{
    width: 70px;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:15px;
    p{
        color:$dark-gray;
    }
    svg{
        width: 32px;
        height:32px;
    }
}