@import "src/styles/variables";

.buttons{
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  margin-top: 1rem;
  gap:2%;
  div{
    display: flex;
    flex-basis: 32%;
    justify-content: center;
    align-items: center;
    border: 1px solid $form-border;
    background: $main-background;
    margin-bottom: 2.5%;
    border-radius: 200px;
    cursor: pointer;
    padding: 1rem;
  }
}

.smallButtons{
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: start;
  align-items: start;
  margin-top: 1rem;
  gap: 1.25%;
  flex:1;
  margin-bottom: 1rem;
  div{
    display: flex;
    width: 19%;
    justify-content: center;
    align-items: center;
    border: 1px solid $form-border;
    background: $main-background;
    margin-bottom: 2.5%;
    border-radius: 2px;
    cursor: pointer;
    padding: 0.5rem;
  }
}

.active{
  background: $primary-color!important;
  border: 1px solid $primary-color!important;
  p{color:$white};
}