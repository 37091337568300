@import "src/styles/variables";

.leafMarker{
    position: absolute;
    height: 40px;
    width: max-content;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    border-radius: 20px;
    img{
        position: absolute;
        width: 33px;
        height: 46px;
        top:0;
        left:0;
        transform: translate(-50%, -60%);
    }
    p{
        font-weight: bold;
        color: white;
        font-size: 16px;
        line-height: 38px;
        padding: 0 1.5rem;
    }
}