@import "src/styles/variables";

.overlay{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.card{
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: $light-gray;
  border-radius: 35px 35px 0 0;
  min-height: 250px;
  h2{
    color: $primary-color;
  }
}

.image{
  display: flex;
  align-items: end;
  flex: 1;
  img{
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}

.bullets{
  display: flex;
  gap:10px;
  justify-content: center;
  span{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: $dark-gray;
  }
}

.activeBullet{
  background: $primary-color!important;
  width: 28px!important;
}