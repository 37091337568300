.dark-theme{
  // primary
  --cms-primary: #ff7b90;
  --cms-primary-inner-text: white;
  --cms-primary-hover: #ff6782;
  --cms-primary-inner-text-hover: white;

  // secondary
  --cms-secondary: #526e93;
  --cms-secondary-inner-text: white;
  --cms-secondary-hover: #728794;
  --cms-secondary-inner-text-hover: white;

  // third
  --cms-third: #c4c4c4;
  --cms-third-inner-text: white;
  --cms-third-hover: #7c7c7c;
  --cms-third-inner-text-hover: white;

  // use cases
  --cms-background:#3a3a3a;
  --cms-text-color: #d3d3d3;
  --cms-disable-color: #a09c9c;
  --cms-confirm-btn: #40b400;
  --cms-confirm-btn-inner: #fff;
  --cms-confirm-btn-hover: #68b934;
  --cms-confirm-btn-inner-hover: #fff;
  --cms-delete-btn: #ff0082;
  --cms-delete-btn-inner: #ffffff;
  --cms-delete-btn-hover: #ff61ae;
  --cms-delete-btn-inner-hover: #ffffff;

  //overall colors
  --cms-white: #252525;
  --cms-black:#ffffff;
  --cms-light-gray:#3e3e3e;
  --cms-green: #40b400;
  --cms-blue: #00bdff;
  --cms-red: #ff0000;
  --cms-transparent: transparent;

  //form colors
  --cms-form-border: #646464;
  --cms-form-background: rgba(255, 255, 255, 0.07);

  // shadows
  --cms-shadow-a: rgba(0, 0, 0, 0.23);
  --cms-shadow-b: rgba(0,0,0,0.05);
}