@import "src/styles/variables";

.card{
    background: $light-gray;
    padding: 1rem;
    border-radius: 15px;
}

.row{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap:10px;
    span{
        text-transform: capitalize;
        color: $dark-gray;
    }
}