@import "src/styles/variables";

.titles{
    color:$mid-gray;
    text-transform: capitalize;
}

.photos{
    display: flex;
    width: 100%;
    overflow: hidden;
    gap:15px;
    img{
        width: 70%;
        height: 150px;

    }
}