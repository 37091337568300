@import "src/styles/variables";

.languageCard{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $light-gray;
  padding: 1rem;
  border-radius: 15px;
  img{
    width: 32px;
    height: 32px;
    border-radius: 16px;
    object-fit: cover;
    border: 2px solid $mid-gray;
  }
}

.active{
  background: #0050B320;
}