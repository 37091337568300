@import "src/styles/variables";

.container {
  display: flex;
  width:100vw;
  height: 100vh;
  background: rgba(0,0,0, 0.5);
  padding: 1.5rem;
  position: relative;
}

.contents{
  width: 100%;
  background: $white;
  border-radius: 20px;
  box-shadow: $box-shadow-large;
  height: fit-content;
}

.top{
  align-items: start;
}

.bottom{
  align-items: end;
}

.navBottom{
  align-items: end;
  padding-bottom: calc(1.5rem + 60px);
}

.center{
  align-items: center;
}

