@import "src/styles/variables";

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  >*{
    pointer-events: all;
  }
}

.searchBar{
  display: flex;
  width: 100%;
  gap:0.75rem;
  padding: 10px;
}

.searchIcon{
  background: $white!important;
  width: 56px;
  height: 56px;
  border-radius: 40px;
  top:0.75rem;
  left: 0.75rem;
  -webkit-transition: .4s;
  transition: .4s;
}

.searchOn{
  width: calc(100% - 1.5rem);
}

.button{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-button;
  cursor: pointer;
  background: $primary-color;
}

.infoIcon{
  width: 38px;
  height: 38px;
  border-radius: 19px;
  top: 76px;
  left: 1.2rem;
  background: $white;
}

.start{
  width: 72px;
  height: 72px;
  border-radius: 36px;
  bottom: 0.75rem;
  right: 0.75rem;

}

.info{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  height: 36px;
  right: 0.75rem;
  top:1.15rem;
  background: #F0F5FF;
  border: 1px solid #ADC6FF;
  border-radius: 18px;
  min-width: 120px;
  -webkit-transition: .4s;
  transition: .4s;
}

.searchOnInfo{
  top:5rem;
}

.pos{
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  gap:0.5rem;
  right:0.75rem;
  bottom:0.75rem;
}

.locator{
  position: absolute;
  width: 50px;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%, -100%);
}