@import 'src/styles/variables';

.container{
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  textarea{
    padding: 0.5rem 0.75rem;
    border: 1px solid $form-border;
    border-radius: 2px;
  }
  label{
    margin-bottom: 0.25rem;
  }
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 26px;
    height: 26px;
    padding: 4px;
    bottom:5px;
    right: 10px;
    cursor: pointer;
  }
}