@import "src/styles/variables";

.container{
  position: relative;
  svg {
    height: 50%;
    margin: auto;
    display: block;
  }
  
  path {
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .grey {
    stroke: $white;
    stroke-width: 3px;
  }
  
  .purple {
    fill: $green;
    border-radius: 3px;
  }
}

.caption{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
}