@import "src/styles/variables";

.container{
    align-items: center;
        padding: 5px 16px;  
        background: #F0F5FF;
        border: 1px solid #D6E4FF;
        border-radius: 50px;

        p{
            color:#061178;
        }
}