@import 'src/styles/variables';


.card{
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  background: $light-gray;
  border-radius: 35px 35px 0 0;
  h2{
    color: $primary-color;
  }
}

.overlap{
  margin-top: -35px;
  z-index: 3;
}

.white{
  background: $white!important;
}

.shadow{
  box-shadow: $box-shadow-large;
}

.image{
  display: flex;
  align-items: end;
  flex: 1;
  position: relative;
  padding: 0 1.5rem;
  img{
    position: absolute;
    width: calc(100% - 3rem);
    height: 100%;
    object-fit: contain;
    object-position: bottom;
    z-index:-1;
  }
}

.jsx{
  width: 100%;
  display: flex;
  flex: 1;
}

.header{
  width:100%;
}

.bottomSheet{
  width:100%;
  padding: 1.5rem;
  background: $white;
  box-shadow: $box-shadow-large;
  border-radius: 40px 40px 0 0;
}

.sidebar{
  display: flex;
  flex-direction: column;
  width:400px;
  height: 100%;
  padding: 1.5rem;
  background: $white;
  align-items: center;
  .sideContents{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
  }
}

.contents {
  display: flex;
  flex:1;
}