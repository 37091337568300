@import 'src/styles/variables';

.container{
	display: flex;
	flex-direction: row-reverse;
	flex: 1;
	height: 100svh;
	overflow: hidden;
}

.content{
	flex: 1;
	padding-left: 60px;
	height: 100%;
}

.nav {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60px;
	height: 100%;
	padding: 7px;
	z-index: 20;
	left:0;
	background: $white;
	box-shadow: $box-shadow-large;
}

.button{
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	p{
		color: $dark-gray;
	}
	:hover{
		color: $primary-color!important;
	}
}

.active{
	p{
		color: $primary-color!important;
	}
}

.navDivider{
	width: 30px;
	border-top: 1px solid $form-border;
	margin: 4px 0;
}

@media only screen and (max-width: 768px) {
	.container{
		flex-direction: column;
	}
	.content{
		flex: initial;
		padding-left: initial;
		height: calc(100% - 72px);
	}
	.nav {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: 72px;
		padding: 7px 30px;
		bottom: 0;
	}
	.navDivider{
		width: 0;
		border-top: none;
		height: 30px;
		margin: initial;
	}
}
