@import 'src/styles/variables';

.container{
  display: flex;
}

.mainLabel{
  width: 100%;
}

.items{
  position: relative;
  width:50px;
  height: 28px;
  background: $mid-gray;
  border-radius: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  span{
    position: absolute;
    width: 22px;
    height: 22px;
    top:3px;
    left:3px;
    background: $white;
    border-radius: 11px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}

.active{
  background: $primary-color;
  span{
    left:25px;
  }
}