@import "src/styles/variables";

.timer{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $light-gray;
  border-radius: 15px;
  padding: 1.5rem 3rem;
  margin-bottom: 1.5rem;
}