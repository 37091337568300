@import "src/styles/variables";

.wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.details{
    display: flex;
    gap:10px;
    img{
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
}

.name{
    display: flex;
    width:100%;
    justify-content: space-between;
    p{
        color:$mid-gray;
    }
}